<template>
  <div
    class="text-style display-block w-100 h-100 outline-none"
    :style="{...style,...boxShadow,...transform}"
    v-html="result"
  ></div>
</template>

<script>
import ModComsStyleMixin from 'mixin/ModComsStyleMixin'
export default {
  mixins: [ModComsStyleMixin],
  computed: {
    result () {
      const { value, data } = this.data
      if (value) return value
      const { config, source, status } = data
      if (!config || source !== 'posMenu' || !status || config.dataType !== 'discountPrice') return ''
      return config.disPriceHolder
        ? `<img src=${config.disPriceHolder} alt="" />`
        : '<span>/</span>'
    },
    style () {
      const { fontFamily, style, height } = this.data
      const { borderStyle, borderWidth, borderColor } = style
      const _style = {
        fontSize: style.fontSize + 'px',
        letterSpacing: style.letterSpacing + 'px',
        fontFamily,
        minHeight: height + 'px',
        borderRadius: style.borderRadius + 'px',
        padding: style.padding + 'px',
        borderStyle,
        borderWidth: borderWidth + 'px',
        borderColor
      }
      return Object.assign({ ...style }, _style)
    }
  },

  methods: {}
}
</script>
