<!--
 * @Author: 伽蓝
 * @Date: 2021-01-20 13:55:47
 * @LastEditTime: 2022-03-29 15:01:41
 * @LastEditors: Please set LastEditors
 * @FilePath: /h5-web/src/components/PagesLayers.vue
 * @Description:
 * 代码不规范,调试泪两行
-->
<template>
    <!-- 主体内容 -->
    <div class="po-r" :style="background">
        <v-background-img :width="width" :data="page.backgroundImage"></v-background-img>
        <div v-if="isLivePlay" class="po-a r-0 b-0 t-0 l-0 live-play-bg"> </div>
        <div class="po-a origin-l-t" v-for="(layer, index) in page.layers" :key="layer.onlyKey" :id="layer.onlyKey"
            :class="layer.onlyKey" :style="layersStyle(layer,index)">
            <component v-bind:is="getCurrentComponent(layer.type)" :data="layer" :page-index="pageIndex"
                :layer-index="index" :current-page="currentIndex" :video-list="page.videoList"
                :video-loop="page.videoLoop" :program-time="page.programTime-0" :video-muted="videoMuted"
                :play-animation-switch="playAnimationSwitch" :menuData="menuData"></component>
        </div>
    </div>
</template>

<script>
import ImportComsMixin from 'mixin/ImportComsMixin'
import { ENTER_ANIME_NAME_LIST } from 'libs/DocumentAnimates'
import { COMS_LIST } from 'libs/BaseConfig'
export default {
  data () {
    return { playAnimationSwitch: false }
  },
  mixins: [ImportComsMixin],
  props: {
    page: {
      type: Object
    },
    pageIndex: {
      type: Number
    },
    currentIndex: {
      type: Number
    },
    videoMuted: {
      type: Boolean
    },
    width: {
      type: Number
    },
    menuData: Object
  },
  watch: {
    async currentIndex (val) {
      val !== this.pageIndex && this.$set(this, 'playAnimationSwitch', false)
      val === this.pageIndex && this.$set(this, 'playAnimationSwitch', true)
    }
  },
  computed: {
    background () {
      const { backgroundColor } = this.page
      return { backgroundColor }
    },
    isLivePlay () {
      const index = this.page.layers.findIndex(item => {
        return item.type === 'livePlay'
      })
      return (index >= 0)
    }
  },
  methods: {
    layersStyle (layer, index) {
      const opacity = this.isEnterAnimeName(layer)
      return {
        opacity,
        width: layer.width + 'px',
        [layer.type === 'text' ? 'minHeight' : 'height']: layer.height + 'px',
        top: layer.top + 'px',
        left: layer.left + 'px',
        zIndex: this.getIndex(layer, index)
      }
    },
    // 获取图层索引层级
    getIndex (layer, index) {
      const h5Render = this.page.h5Render
      const firstVideoIndex = this.page.layers.findIndex(item => item.type === 'video')
      if (layer.type === 'livePlay') {
        return 0
      }
      if (layer.type === 'orderDetails') {
        return 900
      }
      if (layer.type === 'video' && h5Render != 1) {
        return 1000 + index
      }
      if (layer.type === 'image' && layer.appShow && !layer.topOfVideoLayer && firstVideoIndex > -1 && index > firstVideoIndex) {
        return 1000 + index
      }
      if (['text', 'image'].includes(layer.type) && layer.topOfVideoLayer) {
        return 1200 + index
      }
      if (layer.type === 'cameraMonitor') {
        return 1500 + index
      }
      if (layer.type === 'transparentVideo') {
        return 2000
      }
      if (layer.type === 'snow') {
        return 3000
      }
      return index + 2
    },
    isEnterAnimeName (layer) {
      const { animations } = layer
      const opacity = layer.opacity
      if (animations.length === 0) return opacity
      const result = ENTER_ANIME_NAME_LIST.indexOf(animations[0].startClass || '')
      if (result >= 0) return 0
      if (result < 0) return opacity
    },
    getCurrentComponent (type) {
      // if (type === 'video' && this.$globle.type === 'mp') return 'VVideoJS'
      return COMS_LIST[type]
    }

  }
}
</script>
<style  lang="stylus" rel="stylesheet/stylus">
.live-play-bg {
    background-color: #313131
}
</style>
